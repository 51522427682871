import * as actionTypes from '../actions/actionTypes'

const dataReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.DATA:
			return { ...state, data: action.payload }
		default:
			return state
	}
}

export default dataReducer