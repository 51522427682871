import React from 'react'
import { Link } from "react-router-dom"
import { server } from '../App'

function Products(props) {
    return (
        <>
            <div className="row header header-items">

                <div className="col-3 header-item header-back" onClick={() => props.history.goBack()}>
                    <i className="fas fa-arrow-left"></i>
                </div>

                <div className="col-6 header-item header-text">
                    {props.location.state.category}
                </div>
                <div className="col-3 header-item">

                </div>
            </div>
            <div className="container">
                <div className="row">
                    {
                        props.location.state.products && props.location.state.products.length>0 ?
                            props.location.state.products.map(element => {
                                return (
                                    <div key={element._id} className="col-12 product">
                                        <div className="row product-a">
                                            <div className="col-4">
                                                <img className=" img-responsive product-image"
                                                    src={server + 'uploads/' + element.image} />
                                                
                                            </div>
                                            <div className="col-8">
                                                <div className="product-title">{element.productName}</div>
                                                <div className="product-text">{element.detail}</div>
                                                <div className="">{element.price}$</div>
                                            </div>
                                            <hr className="product-hr" />
                                        </div>
                                    </div>
                                )
                            }) : <div>Bu kategoride henüz ürün yok. <br />
                                Ürün eklememiz veya kategoriyi kaldırmamız için lütfen bize bildirin.
                                </div>
                    }

                </div>
            </div>
        </>
    )
}

export default Products