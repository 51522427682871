import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Anasayfa from './screens/Anasayfa'
import Kategorideki_Urunler from './screens/Urunler'
import configureStore from './redux/reducers/configureStore'
import { Provider } from 'react-redux'

export const server = 'https://qr-server.teknozip.com/'
// export const server = 'http://192.168.1.28/'

const store = configureStore()

function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/:lang/:companyID" component={Anasayfa} />
            <Route path="/urunler" component={Kategorideki_Urunler} />
            <Route path="/:companyID" children={<Anasayfa />} />
          </Switch>
        </Router>
      </Provider>
    </>
  );
}

export default App;
