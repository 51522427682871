import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { server } from '../App'


function Home({ dataReducer, setData }) {
    let { companyID } = useParams();
    const [company, setAllData] = useState()
    const history = useHistory()


    useEffect(() => {
        if (dataReducer.data) {
            setAllData(dataReducer.data.company.data)
        } else {
            getData()
        }
    }, [companyID])

    const getData = async () => {
        const company = await axios.get(server + 'api/', {
            params: {
                companyID
            }
        })
        if (company.status) {
            setAllData(company.data.data)
        }
        setData({
            "company": company.data
        })
    }

    const onClickCategory = (category) => {
        history.push({
            pathname: '/urunler',
            state: { products: category.products, category: category.categoryName }
        })
    }

    return (
        <>
            <div className="row header">
                <div className="col-6 header-item header-text">
                    {company ? company.name : null}
                </div>
            </div>
            <div className="container categories-container">
                <div className="row">
                    {
                        company ?
                            company.categories ?
                                company.categories.map(element => {
                                    return (
                                        <div key={element.category} className="col-6 category">
                                            <div className="category-a" onClick={() => onClickCategory(element)}>
                                                <div className="bottom-bg" />
                                                <div className="item-text">
                                                    {element.categoryName}
                                                </div>
                                                <img alt={element.categoryImage} className="img-responsive category-image" src={server + 'uploads/' + element.categoryImage} />
                                            </div>
                                        </div>
                                    )
                                }) : null : null
                    }
                </div>
            </div>
        </>
    )
}


const mapDispatchToProps = dispatch => ({
    setData: (data) => {
        dispatch({ type: 'DATA', payload: data }
        )
    }
})

const mapStateToProps = state => ({
    dataReducer: state.dataReducer
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)